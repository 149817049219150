import API from '../api';
import axiosClient from '../utils/axiosClient';

const getUserPayments = async (data: any) => {
	const res = await axiosClient.get(API.PAYMENTS.paymentInit, {
		params: {...data.filter, page: data.page, take: data.take, order: 'DESC'}
	});

	return res?.data;
};

const USERTRANSACTIONS = {
	getUserPayments
};

export default USERTRANSACTIONS;

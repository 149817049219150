import {Divider} from 'antd';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {getBalanceString} from '../../../../utils/heplerFuncs/functions';
import i18n from '../../../../utils/translate/i18';

const PaymentHistoryItem = ({payment}: any) => {
	let {t} = useTranslation();

	let paymentHistoryValues = [
		{
			label: t('pages.account.paymentHistoryPage.serviceType'),
			value: payment?.type[i18n.language] ?? 'Tariff name'
		},
		{
			label: t('pages.account.paymentHistoryPage.ticketItem.ticketAbout.paymentMethod'),
			value: payment?.provider[`${i18n.language}`] ?? 'Payment Method'
		},
		{
			label: t('pages.account.paymentHistoryPage.ticketItem.ticketAbout.cost'),
			value: getBalanceString(t, payment?.amount)
		},
		{
			label: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'),
			value: payment?.status[i18n.language]
		}
		// {
		// 	label: t('pages.account.paymentHistoryPage.outgoingAccount'),
		// 	value: '*PAN code*'
		// }
	];

	return (
		<div className="payment-history__item">
			<div className="payment-history__row">
				<div className="payment-history__top" style={{padding: 0}}>
					<div className="payment-history__number">
						<span>{moment(payment.transactionDate).format('DD')} </span>
						<span>{t(`months.${moment(payment.transactionDate).month()}`)} </span>
						<span>{moment(payment.transactionDate).format('YYYY')} </span>
						<span>{moment(payment.transactionDate).format('HH:mm')} </span>
					</div>
				</div>
				<Divider style={{background: '#009E83'}} />
				<div className="payment-history__info">
					<div className="payment-history__info-row">
						{paymentHistoryValues.map((elem: any, index: number) => {
							return (
								<div className="payment-history__info-item" key={index}>
									<div className="payment-history__info-title">
										<p>{elem.label}</p>
									</div>
									<div className="payment-history__info-main">
										<p>{elem.value}</p>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentHistoryItem;

import {Checkbox, DatePicker, Input, Rate, TimePicker} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';
import {memo, useEffect, useState} from 'react';
import FileUploadField from '../FileUploadField';
import RadioField from '../RadioField';
import SelectField from '../SelectField';
import {useTranslation} from 'react-i18next';

const CaseComponent = ({
	type,
	inputProps,
	datePickerProps,
	rangePickerProps,
	weekPickerProps,
	selectProps,
	className,
	allowClear, // New prop to control allowClear dynamically
	uploadProps,
	rateProps,
	radioProps,
	checkboxProps,
	textAreaProps,
	checkboxGroupProps,
	getFieldValue,
	...rest
}: any) => {
	const [mode, setMode] = useState('year'); // Initialize mode state with 'year' as default
	const [formattedPhoneValue, setFormattedPhoneValue] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [criteria, setCriteria] = useState({
		minLength: false,
		hasUpperCase: false,
		hasNumber: false,
		hasSpecialChar: false,
		haslowerCase: false
	});
	const [showCriteria, setShowCriteria] = useState(false);
	const handlePanelChange = (value: any, newMode: any) => {
		setMode(newMode); // Update mode state when panel changes
	};

	useEffect(() => {
		// Pre-process the initial value to remove the "+" if present
		if (type === 'phone' && rest.value) {
			const newValue = rest.value.startsWith('+') ? rest.value.substring(1) : rest.value;
			setFormattedPhoneValue(newValue);
		}
	}, [rest.value, type]);

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		// Check if the value is a valid phone number and modify it
		if (/^\+?\d*$/.test(value)) {
			// Remove all non-digit characters
			const formattedValue = value.replace(/\D/g, '');
			// Add "+" sign if not already present
			const newValue = formattedValue.startsWith('+') ? formattedValue : `+${formattedValue}`;
			setFormattedPhoneValue(formattedValue);
			// Update the input value
			rest.onChange(newValue);
		}
	};

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		setCriteria({
			minLength: value.length >= 8,
			hasUpperCase: /[A-Z]/.test(value),
			hasNumber: /[0-9]/.test(value),
			hasSpecialChar: /[!@#$%^&*+,./:;<=>?@[\]^_`{|}~£-]/.test(value),
			haslowerCase: /[a-z]/.test(value)
		});
		setPasswordValue(value);
		rest.onChange(value);
	};
	let {t} = useTranslation();
	switch (type) {
		case 'text':
			return <Input {...rest} {...inputProps} allowClear={allowClear} />;

		case 'password':
			return (
				<>
					<Input.Password
						{...rest}
						{...inputProps}
						value={passwordValue}
						className={className}
						onFocus={() => setShowCriteria(true)}
						onChange={handlePasswordChange}
					/>
					{rest.showCriteria && (
						<ul className={`password-criteria ${showCriteria ? 'visible' : ''}`}>
							<li className={criteria.minLength ? 'valid' : ''}>
								{t('messages.error.passwordAtLeastChars')}
							</li>
							<li className={criteria.hasUpperCase ? 'valid' : ''}>
								{t('messages.error.passwordAtLeastCharsCapitalLetter')}
							</li>
							<li className={criteria.hasNumber ? 'valid' : ''}>
								{t('messages.error.passwordAtLeastOneNumber')}
							</li>
							<li className={criteria.hasSpecialChar ? 'valid' : ''}>
								{t('messages.error.passwordAtLeastOneSpecialCharacter')}
							</li>
							<li className={criteria.haslowerCase ? 'valid' : ''}>
								{t('messages.error.passwordAtLeastOneSmallLetter')}
							</li>
						</ul>
					)}
				</>
			);
		case 'textArea':
			return <Input.TextArea {...rest} {...textAreaProps} />;
		case 'phone':
			return (
				<Input {...rest} {...inputProps} value={formattedPhoneValue} onChange={handlePhoneChange} />
			);
		case 'date':
			if (datePickerProps?.picker === 'time') {
				return (
					<TimePicker
						{...rest}
						{...datePickerProps}
						value={
							rest.value &&
							(datePickerProps?.format === 'HH:mm'
								? dayjs(rest.value, 'HH:mm')
								: dayjs(rest.value))
						}
						style={{width: '100%'}}
					/>
				);
			}
			if (rest.value) {
				return (
					<DatePicker
						placeholder={datePickerProps?.format === 'YYYY' ? 'YYYY' : 'DD-MM-YYYY'}
						format="DD-MM-YYYY"
						{...rest}
						{...datePickerProps}
						value={
							rest.value &&
							(datePickerProps?.format === 'YYYY'
								? dayjs().year(rest.value)
								: dayjs(rest.value))
						}
						style={{width: '100%'}}
					/>
				);
			} else {
				return (
					<DatePicker
						mode={mode}
						placeholder={datePickerProps?.format === 'YYYY' ? 'YYYY' : 'DD-MM-YYYY'}
						format="DD-MM-YYYY"
						onPanelChange={handlePanelChange}
						{...rest}
						{...datePickerProps}
						value={
							rest.value &&
							(datePickerProps?.format === 'YYYY'
								? dayjs().year(rest.value)
								: dayjs(rest.value))
						}
						style={{width: '100%'}}
					/>
				);
			}

		case 'dateRange':
			return (
				<DatePicker.RangePicker
					format="DD-MM-YYYY"
					{...rest}
					{...rangePickerProps}
					style={{width: '100%'}}
				/>
			);
		case 'weekPicker':
			return (
				<DatePicker
					placeholder=""
					{...rest}
					{...weekPickerProps}
					value={rest.value && moment(rest.value)}
					disabledDate={
						typeof weekPickerProps?.disabledDateBy === 'function'
							? date => weekPickerProps?.disabledDateBy?.(date, getFieldValue)
							: weekPickerProps?.disabledDate
					}
					picker="week"
					style={{width: '100%'}}
				/>
			);
		case 'select':
			return <SelectField {...rest} {...selectProps} />;
		case 'file':
			return <FileUploadField {...rest} {...uploadProps} className={className} />;
		case 'rate':
			return <Rate {...rest} {...rateProps} character={({index}: {index: number}) => index + 1} />;
		case 'radio':
			return <RadioField {...rest} {...radioProps} />;
		case 'checkbox':
			return <Checkbox {...rest} {...checkboxProps} />;
		case 'checkboxGroup':
			return <Checkbox.Group {...rest} {...checkboxGroupProps} />;
		case 'color':
			return <Input {...rest} {...inputProps} type="color" />;
		default:
			return null;
	}
};

export default memo(CaseComponent, (prev: any, newState: any) => {
	// Memoization logic
	let isEqual = true;

	if (isEqual) isEqual = prev.value === newState.value;
	if (isEqual) isEqual = prev.disabled === newState.disabled;
	if (isEqual) isEqual = prev.placeholder === newState.placeholder;
	if (_.has(prev, 'checked') && isEqual) isEqual = prev.checked === newState.checked;
	if (_.has(prev, 'fetchParams') && isEqual) isEqual = !!!Object.keys(newState).length;

	return isEqual;
});

import {maxChar, minChars, numberOnlyRule, passwordRules} from '../../../utils/rules/validation';
import {ModalFormFieldTypes, TranslationFuncType} from '../../../utils/types';

interface ResetPassword {
	(t: TranslationFuncType, dispatch: (data: any) => void, isLoading?: boolean): ModalFormFieldTypes[];
}

const fields: ResetPassword = (t, dispatch, isSuccess) => [
	{
		name: 'new-password',
		className: 'passwordInput',
		label: t('actions.currentnewPassword'),
		type: 'password',
		showCriteria: true,
		rules: [passwordRules(t)],
		autoComplete: 'new-password',
		inputProps: {
			autoComplete: 'new-password'
		},
		colProps: {
			xs: 24
		}
	},
	{
		name: 'rePassword',
		className: 'passwordInput',
		label: t('actions.repetnewpassword'),
		autoComplete: 'new-password',
		type: 'password',
		inputProps: {
			autoComplete: 'new-repassword'
		},
		dependencies: ['new-password'],
		rules: [
			passwordRules(t),
			{required: true, message: t('messages.requiredField')},
			{min: 8, message: t('messages.passwordMinLength')},
			({getFieldValue}: {getFieldValue: (name: any) => any}) => ({
				validator(_: any, value: any) {
					if (value !== getFieldValue('new-password')) {
						return Promise.reject(new Error(t('messages.error.passwordNotMatch')));
					}
					return Promise.resolve();
				}
			})
		],
		colProps: {
			xs: 24
		}
	}
];
export default fields;

import {Select, Tooltip} from 'antd';
import _ from 'lodash';
import {CaretDown} from 'phosphor-react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import services from '../../../services';
import i18n from '../../../utils/translate/i18';

const {Option} = Select;

const SelectField = (props: any) => {
	const {
		value,
		fieldValue,
		options: _options,
		customApiFetch = {path: '', labelKey: '', valueKey: '', notSendKeys: [], addFetchID: false},
		hiddenOptions,
		fetchId,
		depFieldKey,
		depOptions,
		fetchParams: _fetchParams,
		disabled,
		startWord,
		mode,
		dictionaryApiPath,
		dictionaryParams,
		onChange,
		selectFirstAsDefault,
		showSearch,
		disabledOptions,
		disabledOptionTitle,
		...rest
	} = props;
	const {t} = useTranslation();
	const [options, setOptions] = useState(_options || []);
	const [fetchParams, setFetchParams] = useState({});
	const newValue = Array.isArray(value) ? value.map(el => el?.id || el) : value;
	const isAllSelected = useRef<boolean>(false);
	useEffect(() => {
		const newData = _.cloneDeep(_fetchParams || {});
		if (customApiFetch?.notSendKeys?.length) {
			customApiFetch?.notSendKeys.forEach((key: string) => {
				if (newData[key]) {
					delete newData[key];
				}
			});
		}

		if (!_.isEqual(fetchParams, newData)) {
			setFetchParams(newData);
		}
	}, [_fetchParams, customApiFetch.notSendKeys, fetchParams]);

	const fetchOptions = useCallback(async () => {
		if (mode === 'multiple' && options.length) return;

		if (customApiFetch.path) {
			const newPath =
				fetchId && customApiFetch?.addFetchID ? customApiFetch.path + fetchId : customApiFetch.path;
			const depLength = Array.isArray(depFieldKey)
				? customApiFetch?.notSendKeys?.length
					? depFieldKey.length - customApiFetch?.notSendKeys?.length
					: depFieldKey.length
				: customApiFetch?.notSendKeys?.length
				? 0
				: 1;
			const fetchLength = Object.values(fetchParams).reduce((retVal: number, val: any) => {
				if (Array.isArray(val) && !!!val.length) {
					retVal > 0 && retVal--;
				} else if (Array.isArray(val) && !!val.length) {
					retVal++;
				} else if (!!val) {
					retVal++;
				} else if (!!!val) {
					retVal > 0 && retVal--;
				}
				return retVal;
			}, 0);
			if (
				depLength === fetchLength ||
				customApiFetch?.addFetchID ||
				(!depFieldKey && !options.length)
			) {
				await services.SETTINGS.getByCustomPath(newPath, {...fetchParams, all: true})
					.then(async res => {
						let data = res.data || res.items;
						setOptions(() => {
							const optionsData = data?.map((opt: any) => ({
								label:
									typeof customApiFetch.labelKey === 'function'
										? customApiFetch.labelKey(opt)
										: opt[customApiFetch.labelKey],
								value:
									typeof customApiFetch.valueKey === 'function'
										? customApiFetch.valueKey(opt)
										: opt[customApiFetch.valueKey],
								hoverName: customApiFetch?.hoverName
									? typeof customApiFetch.hoverName === 'function'
										? customApiFetch.hoverName(opt)
										: opt[customApiFetch.hoverName]
									: '',
								tariffId: opt?.tariffId
							}));
							if (mode === 'multiple') {
								isAllSelected.current = optionsData?.length === newValue?.length;
							}
							return optionsData;
						});
					})
					.catch(console.log);
			}
		} else if (dictionaryApiPath) {
			services.SETTINGS.fetchOptions(dictionaryApiPath, dictionaryParams).then(res => {
				const filterRes =
					hiddenOptions?.length || typeof hiddenOptions === 'function'
						? res.filter(el =>
								typeof hiddenOptions === 'function'
									? hiddenOptions(res).includes(el.id)
									: !hiddenOptions.includes(el.id)
						  )
						: res;
				setOptions(() => {
					let data = filterRes.map(opt => ({
						label: opt[i18n.language as 'hy' | 'en'],
						value: opt.id
					})) as any;
					if (mode === 'multiple') {
						isAllSelected.current = data?.length === newValue?.length;
					}
					return data;
				});
			});
		}
	}, [fetchId, fetchParams, customApiFetch.path, value, i18n.language]);

	useEffect(() => {
		fetchOptions();
	}, [fetchOptions, i18n.language]);

	const handleChange = (value: string | string[]) => {
		if (mode === 'multiple') {
			if (value.includes('all')) {
				if (isAllSelected.current) {
					isAllSelected.current = false;
					onChange([]);
				} else {
					isAllSelected.current = true;
					onChange(options.map((el: any) => el.value));
				}
			} else {
				isAllSelected.current = options.length === value.length;
				onChange(value);
			}
		} else {
			onChange(value);
		}
	};

	useEffect(() => {
		if (selectFirstAsDefault && options.length && (value === undefined || value === null)) {
			const newValue = mode === 'multiple' ? [options?.[0]?.value] : options?.[0]?.value;
			handleChange(newValue);
		}
	}, [options]);

	return (
		<Select
			suffixIcon={<CaretDown color="black" size={15} />}
			{...rest}
			disabled={disabled}
			unselectable={'off'}
			style={{height: mode !== 'multiple' ? '50px' : '', ...props.style}}
			showSearch={showSearch}
			optionFilterProp="children"
			filterOption={(input, option) => {
				return option?.children?.toString()?.toLowerCase()?.includes(input.toLowerCase());
			}}
			value={startWord ? `${startWord} ${newValue}` : newValue}
			mode={mode}
			allowClear
			getPopupContainer={trigger => {
				return (
					trigger?.parentNode?.parentElement?.parentElement ||
					trigger?.parentNode?.parentElement ||
					trigger?.parentNode ||
					trigger
				);
			}}
			onChange={handleChange}>
			{mode === 'multiple' && (
				<Option
					key="all"
					value="all"
					className={
						isAllSelected.current
							? 'ant-select-item-option-active ant-select-item-option-selected'
							: ''
					}>
					{t('selectAll')}
				</Option>
			)}
			{options.map((el: any, index: number) => {
				let isDisabled =
					el?.status === 0 || typeof disabledOptions === 'function'
						? disabledOptions(el, value, options).includes(el.value)
						: disabledOptions?.includes(el.value);
				return (
					<Option
						disabled={isDisabled}
						key={el.value || index}
						value={el.value}
						title={disabledOptionTitle && isDisabled ? disabledOptionTitle : el.label}>
						{el.label}
					</Option>
				);
			})}
		</Select>
	);
};

export default SelectField;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IPaymentHistory} from '../utils/types';
import SERVICES from '../services';
import helperFuncs from '../utils/heplerFuncs/functions';

const initialState: any = {
	paymentHistory: []
};

export const getPaymentHistoryThunk: any = createAsyncThunk(
	'user/paymentHistory',
	async (state: any, {rejectWithValue}) => {
		let data: any;
		try {
			const filter = helperFuncs.mutatedateRangeKey(state.filter, ['createdAt']);

			data = await SERVICES.PAYMENTHISTORY.getUserPayments({...state, filter});
		} catch (err) {
			return rejectWithValue(err);
		}
		return new Promise(resolve => resolve(data));
	}
);

const passengerPaymentHistory = createSlice({
	name: 'paymentHistory',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getPaymentHistoryThunk.fulfilled, (state, action) => {
			state.paymentHistory = action.payload.data;
			// {
			// 	id: 422222222265,
			// 	status: '',
			// 	paymentMethod_hy: 'Բանկային քարտ',
			// 	productName_hy: 'Մեկանգամյա  տոմս',
			// 	paymentMethod_en: 'Bank Card',
			// 	productName_en: 'One Use',
			// 	amount: 500,
			// 	createdAt_en: '13 January 2024, 15:00:00',
			// 	createdAt_hy: '13 հունվարի 2024, 15:00:00'
			// }
		});
		// .addCase(getPaymentHistoryThunk.fulfilled, (state, action) => {})
		// .addCase(getPaymentHistoryThunk.rejected, (state, action: any) => {});
	}
});

export default passengerPaymentHistory.reducer;

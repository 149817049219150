import API from '../api';
import axiosClient from '../utils/axiosClient';

export interface DictionaryType {
	id: number;
	hy: string;
	en: string;
	ru?: string;
}

export type dictStringTypes =
	| 'userType'
	| 'genders'
	| 'paymentTypes'
	| 'cities'
	| 'notificationTypes'
	| 'route-types'
	| 'route-working-modes'
	| 'company-legal-forms'
	| 'route-modes'
	| 'holder-statuses'
	| 'driver-statuses'
	| 'storage-statuses'
	| 'tariff-holder-types'
	| 'partner-statuses'
	| 'faq-categories'
	| 'contract-statuses'
	| 'tariff-types'
	| 'route-statuses'
	| 'transaction-types'
	| 'enterprises'
	| 'complaints-types'
	| 'transport-categories'
	| 'transport-types'
	| 'complaints-statuses'
	| 'transport-statuses'
	| 'holder-storage-statuses'
	| 'enterprise-types'
	| 'transaction-enterprises'
	| 'transaction-enterprise-types'
	| 'transaction_statuses'
	| 'transaction-payment-types'
	| 'validator-types'
	| 'validator-statuses'
	| 'driver-statuses'
	| 'transfer-statuses'
	| 'payment-statuses';

const getByCustomPath = async (path: string, fetchParams: any): Promise<any> => {
	const response = await axiosClient.get(path, {params: fetchParams});
	return response.data.data || response.data;
};

const fetchOptions = async (dictionary: string, params?: any): Promise<DictionaryType[]> => {
	const response = await axiosClient.get(API.SETTINGS.DICTIONARY + `/${dictionary}`, {params});

	const newData = response.data.data;

	return newData;
};
const SETTINGS = {
	getByCustomPath,
	fetchOptions
};

export default SETTINGS;

import {useTranslation} from 'react-i18next';
import MainTicket from '../../../../Tickets/MainTicket/MainTicket';
import './OwnedTicket.less';
import {Button, Modal} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
// @ts-ignore
import {saveAs} from 'file-saver';
import {fetchImage} from '../../../../../utils/fetchImage/fetchImage';
import i18n from '../../../../../utils/translate/i18';
import TicketIco from './../../../../../assets/icons/ticketsvg.svg';
import {getKeyByLang} from '../../../../../utils/heplerFuncs/functions';
import moment from 'moment';
import defaultqr from './../../../../../assets/default-qr.png';

const OwnedTicket = ({ticket}: any) => {
	const {t} = useTranslation();
	const [open, setOpen] = useState(false);
	const [imageSrc, setImageSrc] = useState('');
	const monthUse = ticket.carrierTypeID === 1;
	const isMobile = useMediaQuery({maxWidth: 480});
	const qrRef = useRef<any>();

	useEffect(() => {
		ticket.qr !== null ? fetchImage(ticket.qr, setImageSrc) : setImageSrc('');
		console.log(imageSrc);

		return () => {
			URL.revokeObjectURL(imageSrc);
		};
	}, [ticket.qr]);

	const handleDownload = () => {
		fetch(imageSrc)
			.then(response => response.blob())
			.then(blob => {
				saveAs(blob, 'qr-code.png');
			})
			.catch(error => console.error('Error downloading image:', error));
	};

	const ticketUsed = ticket.usedAt !== null;
	const ticketInvalid = moment(moment()).diff(moment(ticket.expiredAt)) >= 0;

	return (
		<div className="owned-ticket">
			<div className="owned-ticket__rows">
				<div className="owned-ticket__header" style={{display: 'inline-flex', gap: 15}}>
					<div className="ticket__trips-logo">
						<img src={TicketIco} alt="" />
					</div>
					<p>{ticket[getKeyByLang('tariffName')]}</p>
				</div>
				<div className="owned-ticket__main">
					<MainTicket showTicketIco={true} oneUse={!monthUse} options={ticket} />
					<div className="owned-tikcet__qrcode">
						<Modal
							open={open}
							onCancel={() => setOpen(false)}
							centered
							footer={null}
							width={'auto'}
							closeIcon={false}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									gap: 10
								}}>
								<img
									src={!imageSrc ? defaultqr : imageSrc}
									style={{width: isMobile ? 250 : 300}}
									alt="QR"
								/>
								<Button type="primary" onClick={handleDownload}>
									{t('actions.saveQRCode')}
								</Button>
							</div>
						</Modal>

						<div ref={qrRef} className={`${(ticketUsed || ticketInvalid) && 'qr_expired'}`}>
							<img
								src={!imageSrc ? defaultqr : imageSrc}
								style={{
									width: isMobile ? 250 : 400,
									cursor: ticketUsed || ticketInvalid ? 'unset' : 'pointer'
								}}
								alt="QR"
								onClick={() => {
									!ticketUsed && !ticketInvalid && setOpen(true);
								}}
							/>
							{(ticketUsed || ticketInvalid) && (
								<div
									className="qr_expired-date"
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: 20,
										justifyContent: 'center',
										alignItems: 'center',
										width: '100%'
									}}>
									<div
										className="qr_expired-date__title"
										style={{color: 'red', fontWeight: 700}}>
										{ticketUsed
											? t('pages.account.myTickets.used')
											: t('pages.account.myTickets.inValid')}
									</div>
									<div className="qr_expired-date__main" style={{color: 'red'}}>
										{ticketUsed
											? `${moment(ticket.usedAt).format('DD-MM-YYYY HH:mm')}`
											: `${moment(ticket.expiredAt).format('DD-MM-YYYY HH:mm')}`}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="owned-ticket-description">
					<div className="owned-ticket-description__header">
						<p>{t('pages.account.myTickets.description')}</p>
					</div>
					<div className="owned-ticket-description__main">
						{ticket[`${getKeyByLang('tariffDescription')}`]}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OwnedTicket;
